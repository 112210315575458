import React from 'react';
// Parts
import HomeHeader from './components/HomeHeader';
import HomeAbout from './components/HomeAbout';
import HomeDownloadSection from './components/HomeDownloadSection';
import HomeFooter from './components/HomeFooter';

const HomePage = () => {
  return (
    <main>
      <HomeHeader />
      <HomeAbout />
      <HomeDownloadSection />
      <HomeFooter />
    </main>
  );
};

export default HomePage;
