import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// UI
import { Container, Flex } from 'components';
// Assets
import { Images, Icons } from 'assets';

// Others
export const ButtonIcon = styled.a`
  cursor: pointer;
`;
export const ButtonIconImage = styled.img``;

// Header

export const Header = styled.header(
  ({ theme }) => css`
    height: 100vh;
    background: url(${Images.Background});
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 2rem 0;
    overflow: hidden;
    position: relative;
    ${theme.media.lg} {
      padding: 4rem;
    }
  `
);

export const HeaderImg = styled.img(({ theme }) => {
  return css`
    position: absolute;
    bottom: 0;
    width: 30rem;
    left: 55%;
    transform: translateX(-50%);
    top: 60%;
    width: 40rem;
    @media (max-width: ${theme.breakpoints[0]}) and (min-height: 600px) {
      top: 50%;
    }
    ${theme.media.md} {
      margin-right: -5rem;
      transform: initial;
      left: initial;
      top: initial;
      position: initial;
      display: block;
    }
  `;
});

// About

export const AboutSection = styled.section(
  ({ theme }) => css`
    height: auto;
    padding: 5rem 0;
    overflow: hidden;

    ${theme.media.lg} {
      padding: 11rem 0;
    }
    ${theme.media.md} {
      padding: 7rem 0;
      height: 100vh;
    }
  `
);

export const AboutFlexContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;

export const AboutImage = styled.img(
  ({ theme }) => css`
    width: 100%;
    margin: 2rem auto;
    ${theme.media.xs} {
      width: 60%;
    }
    ${theme.media.md} {
      margin: initial;
      width: initial;
      position: absolute;
      top: 0;
      right: 50rem;
      height: 100%;
    }
  `
);

export const AboutList = styled.ul(
  ({ theme }) => css`
    margin: 0 auto !important;
    ${theme.media.md} {
      flex-basis: 50rem;
      margin-left: calc(100% - 50rem) !important;
    }
  `
);

export const AboutListItemIcon = styled(Icons.CheckIcon)`
  ${({ theme }) => css`
    width: 5rem;
    height: 5rem;
    margin-right: 1.5rem;
    ${theme.media.md} {
      width: 8rem;
      height: 8rem;
    }
  `}
`;
export const AboutListItem = styled.li(
  ({ theme }) => css`
    display: flex;
    margin: 2rem 0;
    h3 {
      font-size: ${theme.fontSizes[1]}px;
      line-height: 1;
      ${theme.media.md} {
        line-height: 1.5;
        font-size: ${theme.fontSizes[2]}px;
      }
    }
  `
);

// DownloadsSection

export const DownloadSection = styled.section(
  ({ theme }) => css`
    padding: 5rem 0;
    background-color: ${theme.colors.teal};
    ${theme.media.lg} {
      padding: 11rem 0;
    }
    ${theme.media.md} {
      padding: 7rem 0;
    }
  `
);

export const DownloadFlex = styled(Flex)`
  ${({ theme }) => css`
    flex-direction: column;
    ${theme.media.sm} {
      flex-direction: row;
    }
  `}
`;

// Footer

export const Footer = styled.footer(
  () => css`
    padding: 3rem 0;
  `
);

export const FooterFlex = styled(Flex)`
  ${({ theme }) => css`
    flex-direction: column;
    ${theme.media.sm} {
      flex-direction: row;
      justify-content: space-between;
    }
  `}
`;

// export const FooterFlex;

export const ButtonLink = styled(Link)(
  ({ theme }) => css`
    text-decoration: none;
    color: ${theme.colors.primary};
    :hover {
      text-decoration: underline;
    }
  `
);
