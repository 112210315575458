// @ts-nocheck
import { useEffect } from 'react';
import config from 'config';

const Redirect = () => {
  useEffect(() => {
    function redirectToApp() {
      const iOS = /iPad|iPhone|Macintosh|iPod/.test(navigator.userAgent);
      const Android = /Android/.test(navigator.userAgent);
      const scheme = 'leiaonline';
      const host = 'leiaonline';
      const pack = 'br.com.leiaonline';
      let newLocation: string = '';
      if (iOS) {
        newLocation = `${host}://`;
        setTimeout(() => {
          window.location = config.ios;
        }, 1000);
      } else if (Android) {
        newLocation = `intent://${host}#Intent;scheme=${scheme};package=${pack};end`;
        setTimeout(() => {
          window.location = config.android;
        }, 1000);
      }
      window.location.href = newLocation;
    }
    redirectToApp();
  }, []);
  return null;
};

export default Redirect;
