import React from 'react';
// UI
import { Flex, Box } from 'components';
// Assets
import { Images } from 'assets';
// Styles
import { ButtonIcon, ButtonIconImage } from './styles';
// Config
import config from 'config';

const SocialButtons = () => {
  return (
    <Flex>
      <Box mr={2}>
        <ButtonIcon href={config.android}>
          <ButtonIconImage src={Images.PlayStore} />
        </ButtonIcon>
      </Box>
      <ButtonIcon href={config.ios}>
        <ButtonIconImage src={Images.AppStore} />
      </ButtonIcon>
    </Flex>
  );
};

export default SocialButtons;
