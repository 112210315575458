import styled, { css } from 'styled-components';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'contained' | 'outlined';
}

const Button = styled.button<ButtonProps>(
  ({ theme }) => css`
    height: 4.5rem;
    background-color: ${theme.colors.white};
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: ${theme.fontSizes[1]}px;
    color: ${theme.colors.secondary};
    border-radius: 4px;
    min-width: 28rem;
    transition: all 0.3s;
    cursor: pointer;
    :hover {
      background-color: ${theme.colors.greyLight};
    }
  `
);

Button.defaultProps = {
  variant: 'contained',
};

export default Button;
