import React from 'react';
import { Container, Text } from 'components';
// Data
import { terms } from './terms.json';

const PrivacyPage = () => {
  return (
    <Container>
      {terms.split('\n').map((t, i) => (
        <Text key={i}>{t}</Text>
      ))}
    </Container>
  );
};

export default PrivacyPage;
