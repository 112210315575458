import React from 'react';
// UI
import { Container, SmallText } from 'components';
// Styles
import { ButtonLink, Footer, FooterFlex } from './styles';

const HomeFooter = () => {
  return (
    <Footer>
      <Container>
        <FooterFlex>
          <SmallText mb={[2, 2, 0]}>
            © Copyright 2020 | Todos os direitos reservados{' '}
            <strong>Leia Online</strong>
          </SmallText>
          <ButtonLink to="/privacidade">
            <SmallText>Política e privacidade | Termo de uso</SmallText>
          </ButtonLink>
        </FooterFlex>
      </Container>
    </Footer>
  );
};

export default HomeFooter;
