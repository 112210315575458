import React from 'react';
// UI
import { H1, H3, Flex, Box, Text } from 'components';
// Styles
import {
  AboutSection,
  AboutListItem,
  AboutList,
  AboutListItemIcon,
  AboutImage,
  AboutFlexContainer,
} from './styles';
import { Images } from 'assets';

const HomeAbout = () => {
  return (
    <AboutSection>
      <AboutFlexContainer>
        <Flex width={1} flexDirection="column" alignItems="center">
          <H1 mb={1} fontSize={[3, 4, 5]} color="secondary">
            O que você ganha?
          </H1>
          <H3 textAlign="center">
            Notícias atualizadas com a credibilidade do
            <strong> Jornal Extra e Jornal Meia Hora</strong>
          </H3>
        </Flex>
        <Flex
          width={1}
          flexWrap="wrap"
          position="relative"
          height={'100%'}
          alignItems="center"
        >
          <AboutImage src={Images.AppsScreens} />
          <AboutList>
            <AboutListItem>
              <AboutListItemIcon />
              <Box flex={1}>
                <H3 lineHeight="1.5" fontWeight="500" mb={'5px'}>
                  Seu jornal impresso na versão digital
                </H3>
                <Text>
                  Com apenas um clique você fica por dentro das principais
                  notícias do Brasil e do Mundo
                </Text>
              </Box>
            </AboutListItem>
            <AboutListItem>
              <AboutListItemIcon />
              <Box flex={1}>
                <H3 lineHeight="1.5" fontWeight="500" mb={'5px'}>
                  30 edições num só lugar
                </H3>
                <Text>
                  Acesso exclusivo a cadernos e editoriais diários para você não
                  perder nada
                </Text>
              </Box>
            </AboutListItem>
            <AboutListItem>
              <AboutListItemIcon />
              <Box flex={1}>
                <H3 lineHeight="1.5" fontWeight="500" mb={'5px'}>
                  Notícias 24horas por dia
                </H3>
                <Text>Notícias atualizadas minuto a minuto</Text>
              </Box>
            </AboutListItem>
            <AboutListItem>
              <AboutListItemIcon />
              <Box flex={1}>
                <H3 lineHeight="1.5" fontWeight="500" mb={'5px'}>
                  Leitura Off-line
                </H3>
                <Text>Baixe seu jornal para ler quando quiser</Text>
              </Box>
            </AboutListItem>
          </AboutList>
        </Flex>
      </AboutFlexContainer>
    </AboutSection>
  );
};

export default HomeAbout;
