import 'styled-components';
import 'styled-system';
import type { DefaultTheme } from 'styled-components';
export { default as MainStyle } from './globalStyles';
export { default as Images } from './Images';
export { default as Icons } from './Icons';

// Edit Breakpoints and Colors here

export enum breakpoints {
  xs = '36em', // 576px
  sm = '48em', // 768px
  md = '62em', // 992px
  lg = '74em', // 1184px
  xl = '90em', // 1440px
}

export enum colors {
  primary = '#635f60',
  secondary = '#343434',
  greyLight = '#e3dedf',
  white = '#fff',
  teal = 'rgb(26,126,152)',
  error = 'red',
  success = 'green',
}

// Interfaces
type BpKeys = keyof typeof breakpoints;
// 16px Bold Regular,
// 50px 500 ,
// 20px 400,500 ,
// 38px regular,
// 14 bold
// Overwritten Global theme of Styled Components
declare module 'styled-components' {
  export interface DefaultTheme {
    colors: typeof colors;
    fontSizes: [14, 16, 20, 28, 38, 50];
    space: [0, 10, 20, 30, 40, 60];
    breakpoints: string[];
    media: typeof breakpoints;
  }
}

const media = (Object.keys(breakpoints) as Array<BpKeys>).reduce(
  (acc: any, cur) => {
    const currBreakpoint = breakpoints[cur];
    acc[cur] = `@media (min-width: ${currBreakpoint})`;
    return acc;
  },
  {}
);

export const theme: DefaultTheme = {
  colors,
  space: [0, 10, 20, 30, 40, 60],
  breakpoints: Object.values(breakpoints),
  fontSizes: [14, 16, 20, 28, 38, 50],
  media,
};
