import React from 'react';
// UI
import { Container, H2 } from 'components';
// Components
import SocialButtons from './SocialButtons';
// Styles
import { DownloadSection, DownloadFlex } from './styles';

const DownloadsSection = () => {
  return (
    <DownloadSection>
      <Container>
        <DownloadFlex alignItems="center" justifyContent="space-between">
          <H2 fontSize={[2, 3, 4]} mb={[2, 2, 0]}>
            Baixe agora nosso aplicativo
          </H2>
          <SocialButtons />
        </DownloadFlex>
      </Container>
    </DownloadSection>
  );
};

export default DownloadsSection;
