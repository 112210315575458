import React from 'react';
// UI
import {
  Container,
  // Button,
  H1,
  H3,
  Flex,
  Box,
} from 'components';
// Components
import SocialButtons from './SocialButtons';
// Styles
import { Header, HeaderImg } from './styles';
// Assets
import { Icons, Images } from 'assets';

const HomeHeader = () => {
  return (
    <Header>
      <Container>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={[2, 3, 3, 3, 5]}
          flexWrap="wrap"
        >
          <Icons.LogoIcon />
        </Flex>
        <Flex justifyContent="space-between">
          <Flex flexDirection="column" justifyContent="center">
            <Box width={['auto', 'auto', '55rem']}>
              <H1 color={'white'} fontSize={[2, 3, 3, 4, 5]}>
                Fonte de informação a qualquer hora na palma da mão
              </H1>
            </Box>
            <H3
              color={'white'}
              fontSize={[1, 2, 2, 2, 3]}
              my={[2, 2, 3]}
              lineHeight={[1.5, 1.5, 1.5, 1.5, '1.8']}
            >
              Não fique desatualizado, baixe <br />
              agora nosso aplicativos nas lojas!
            </H3>
            <SocialButtons />
          </Flex>
          <HeaderImg src={Images.Mockup} />
        </Flex>
      </Container>
    </Header>
  );
};

export default HomeHeader;
