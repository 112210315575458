enum KEYS {
  USER_TOKEN = '@leiaonline/auth-token',
}

// type Keys = typeof KEYS;

const localStorageSave = (key: string, item: string): void => {
  localStorage.setItem(key, item);
};

const localStorageGet = (key: string): string | null => {
  const item = localStorage.getItem(key);
  return item;
};

const localStorageDel = (key: string): void => {
  localStorage.removeItem(key);
};

export const userTokenSave = (token: string) => {
  localStorageSave(KEYS.USER_TOKEN, token);
};
export const userTokenGet = (): string | null => {
  const userToken = localStorageGet(KEYS.USER_TOKEN);
  return userToken;
};
export const userTokenRemove = () => {
  localStorageDel(KEYS.USER_TOKEN);
};
